import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import VideoSlider from './VideoSlider';

function Slider() {
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-slider-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;
                    setwebsiteDetails({ "slider_details": result.slider_details, "template_identity": result.template_identity, "slider_default": result.slider_default, "hotel_menus": result && result.hotel_menu_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    return (
        <div>
            {websiteDetails && websiteDetails.default_value && (websiteDetails.default_value.default_value === "video") ?
                <div>
                    <VideoSlider />
                </div> :
                <section className="section-slider">

                    <Carousel
                        infiniteLoop
                        autoPlay={true}
                        interval={3000}
                        animateOut='fadeOut'
                    >
                        {websiteDetails && websiteDetails.slider_details.map(function (item, i) {

                            return (
                                <div className="slider-responsive" key={i}>
                                    <img src={config.wbImagesEndpoint + '/' + item.slider_image} alt={item.slider_image_alt} />
                                    <div className="slider-text">
                                        <h3 className="slider-caption slider-caption-1">{item.slider_caption}</h3>
                                    </div>
                                </div>

                            )
                        })}

                    </Carousel>
                </section>
            }
        </div>

    )
}

export default Slider;
