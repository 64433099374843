import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const FacilitiesSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [facilitiesSectionData, setFacilitiesSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const facilitySection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-facility-section-data/' + company_id + '/' + template_id
                    );
                    const result = await facilitySection.json();

                    setFacilitiesSectionData({ "facilities_data": result && result.hotel_facilities, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = facilitiesSectionData && facilitiesSectionData.section_details


    return (

        <section className="choose_resort_area" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
            <div className="container">
                {section_details && <div className="center_title">
                    <h2>{section_details.section_title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                </div>}
                    {facilitiesSectionData && facilitiesSectionData.facilities_data && facilitiesSectionData.facilities_data.length > 0 &&
                        <div className="row">
                            <div className="col-md-4 col-md-offset-right-1 col-md-offset-1">
                                <div className="choose_resort_list">
                                    <ul>
                                        {facilitiesSectionData.facilities_data[0].facilities.map(function (item, i) {
                                            if (i % 2 === 0) {
                                                return (
                                                    <li style={{ fontFamily: websitefont }} key={i}><i className="fa fa-caret-right" aria-hidden="true"></i>{item}</li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-md-offset-right-1 col-md-offset-1">
                                <div className="choose_resort_list">
                                    <ul>
                                        {facilitiesSectionData.facilities_data[0].facilities.map(function (item, i) {
                                            if (i % 2 !== 0) {
                                                return (
                                                    <li style={{ fontFamily: websitefont }} key={i}><i className="fa fa-caret-right" aria-hidden="true"></i>{item}</li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>

                    }
            </div>
        </section>


    );
}

export default FacilitiesSection;