import React, { useState,useContext} from 'react';
import { CompanyContext } from '../../global_setting/Store';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';


function WidgetMobile(props) {
    const [companyDetails] = useContext(CompanyContext);
    let hotel_details = props.hoteldata;
    let hotel_id = hotel_details && hotel_details[0].hotel_id;

    const [focusedInput, setfocusedInput] = useState(null)
    const [fromdate, setFromDate] = useState(moment())
    const [todate, setToDate] = useState(moment(fromdate).add(1, 'days'))


    // const hotelName = (e) => {
    //     setHotelId(e);
    // }

    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    var iframe
    let encryptData
    if (isNaN(from_date) && isNaN(to_date)) {
    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotel_id + '|' + "" + '|' + "" + '|' + ""
        encryptData = btoa(allData);
        // iframe = encryptData
    }
    //end

    return (
        <div className="book_direct mobile_widget book-direct-mobile-widget">
            <section className="book_table_area">
                <div className="book_table_inner row m0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2">
                                <h2 style={{ fontWeight: "bold", textAlign: "center", marginBottom: "40px", textDecoration: "underline" }}>Book Direct</h2>
                            </div>
                            <div className="col-md-2">
                                <DateRangePicker
                                    startDate={fromdate}
                                    startDateId="check_in_date"
                                    endDate={todate}
                                    endDateId="check_out_date"
                                    onDatesChange={({ startDate, endDate }) => {
                                        setFromDate(startDate)
                                        setToDate(endDate)
                                    }}
                                    focusedInput={focusedInput}
                                    onFocusChange={focusedInput => setfocusedInput(focusedInput)}
                                    displayFormat='DD-MM-YYYY'
                                    customArrowIcon="|"
                                    block={true}
                                    orientation="vertical"
                                    verticalHeight={568}
                                    readOnly={true}
                                />
                            </div>
                            <br />
                        </div>

                        <div className="book_button_widget">
                            {/* <Link className="book_now_btn"
                                to={{
                                    pathname: config.PATH + 'booknow',
                                    search: '?' + (iframe),
                                }}
                            >Book Now</Link> */}

                        <a className="book_now_btn" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Book Now</a>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default WidgetMobile;