import React, { useState, useEffect } from 'react';
import getUserInfo from './getUserInfo'
import config from '../config';

export const CompanyContext = React.createContext()


const Store = ({ children }) => {
  const [companyId, setCompanyId] = useState(0)


  let comp = window.location.origin;

  let home_url;

  if (comp === config.wbDemoLink) {
    home_url = window.location.href;

  } else {
    home_url = comp.search('https') > -1 ? comp.replace('https://', '') : comp.replace('http://', '');
  }



  // use to get company id and template id
  useEffect(() => {
    if (home_url != '') {
      getUserInfo(home_url, setCompanyId);
    }
  }, [home_url, setCompanyId])

  return (
    <CompanyContext.Provider value={[companyId]}>
      {children}
    </CompanyContext.Provider>
  )
}
export default Store;
