import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const FacebookSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [facebookSectionData, setFacebookSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const facebookSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-facebook-section-data/' + company_id + '/' + template_id
                    );
                    const result = await facebookSection.json();

                    setFacebookSectionData({ "facebook_data": result && result.facebook_data, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = facebookSectionData && facebookSectionData.section_details

    let encoded_url = encodeURIComponent(facebookSectionData && facebookSectionData.facebook_data && facebookSectionData.facebook_data.facebook_link)
    return (

        <div>
            <div className="container">
                {section_details && <div className="center_title">
                    <h2>{section_details.section_title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                </div>}
            </div>

            <div className="text-center facebook_page" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")", paddingBottom: " 30px",paddingTop:"30px" }}>
                <iframe src={"https://www.facebook.com/plugins/page.php?href=" + encoded_url + "&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"}
                    width="340" height="500" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media">
                </iframe>
            </div>
        </div>
    );
}

export default FacebookSection;